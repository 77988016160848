import $ from "jquery";

function asnieres() {
  if ($('#asnier')[0]) {
    
    var fairImgs = [];
    var galerieImgs = [];
    
    $(".asn-flex-foto > img").each(function(){
      fairImgs.push($(this).attr('src'))
      
    });
    
    $(".asnier-galerie-item img").each(function () {
      galerieImgs.push($(this).attr('src'));
    });
    
    var slideIndex = 0;
    
    $('#asnier-slideshow .asnier-slideshow_item').each(function () {
      slideIndex++;
    });
    
    $('#asnier-slideshow').slick({
      nextArrow: '<a class="asnier-modal-button asnier-modal-button_next" href="#">' +
      '   <svg aria-hidden="true" focusable="false" xmlns="http://www.w3.org/2000/svg" version="1.1" width="42" height="42" viewBox="-1 -1 42 42">' +
      '      <circle stroke-width="1" cx="20" cy="20" r="20"></circle>' +
      '      <path stroke-width="1" stroke-linecap="round" fill="none" d="M18,12 L26,20 L18,28"></path>' +
      '   </svg>' +
      '</a>',
      prevArrow: '<a class="asnier-modal-button asnier-modal-button_priv" href="#">' +
      '   <svg aria-hidden="true" focusable="false" xmlns="http://www.w3.org/2000/svg" version="1.1" width="42" height="42" viewBox="-1 -1 42 42">' +
      '      <circle stroke-width="1" cx="20" cy="20" r="20"></circle>' +
      '      <path stroke-width="1" stroke-linecap="round" fill="none" d="M22,12 L14,20 L22,28"></path>' +
      '   </svg>' +
      '</a>',
      variableWidth: true,
      centerMode: true,
      slidesToShow: 1,
      centerPadding: '60px',
      speed: 300,
      infinite: false
    });
    
    $("#asnier-modal").hide();
    
    $(".asn-flex-foto ").each(function() {
      $(this).click(function () {
        if ($('#asnier-slideshow').attr('data-slide-bock') != 'atelier') {
          for (var j = 0; j < slideIndex; j++){
            $('#asnier-slideshow').slick('slickRemove', 0);
          }
          slideIndex = 0;
          for (var j = 0 ; j < fairImgs.length; j++){
            $('#asnier-slideshow').slick('slickAdd',' <div class="asnier-slideshow_item" >' +
              '<img class="asnier-slideshow_img" src="' + fairImgs[j] +'" alt="">' +
              '</div>'
            );
            slideIndex++;
          }
          $('#asnier-slideshow').attr('data-slide-bock', 'atelier');
        }
        $('#asnier-slideshow').slick('slickGoTo', 0);
        setTimeout(800);
        $('#asnier-modal').fadeIn(1000);
      });
    });
    
    $(".asnier-galerie-item ").each(function() {
      $(this).click(function () {
        if ($('#asnier-slideshow').attr('data-slide-bock') != 'galerie') {
          for (var j = 0; j < slideIndex; j++){
            $('#asnier-slideshow').slick('slickRemove', 0);
            console.log('deleted');
          }
          slideIndex = 0;
          for (var j = 0 ; j < galerieImgs.length; j++){
            $('#asnier-slideshow').slick('slickAdd',' <div class="asnier-slideshow_item" >' +
              '<img class="asnier-slideshow_img" src="' + galerieImgs[j] +'" alt="">' +
              '</div>'
            );
            slideIndex++;
          }
          $('#asnier-slideshow').attr('data-slide-bock', 'galerie');
        }
        $('#asnier-slideshow').slick('slickGoTo', 0);
        setTimeout(800);
        $('#asnier-modal').fadeIn(1000);
      });
    });
    
    $('#modal-gallery-close').click(function () {
      $('#asnier-modal').fadeOut();
    });
    
    $('.asnier-atelier-slider').slick({
      nextArrow: '<a class="asnier-modal-button asnier-modal-button_next" href="#">' +
      '   <svg aria-hidden="true" focusable="false" xmlns="http://www.w3.org/2000/svg" version="1.1" width="42" height="42" viewBox="-1 -1 42 42">' +
      '      <circle stroke-width="1" cx="20" cy="20" r="20"></circle>' +
      '      <path stroke-width="1" stroke-linecap="round" fill="none" d="M18,12 L26,20 L18,28"></path>' +
      '   </svg>' +
      '</a>',
      prevArrow: '<a class="asnier-modal-button asnier-modal-button_priv" href="#">' +
      '   <svg aria-hidden="true" focusable="false" xmlns="http://www.w3.org/2000/svg" version="1.1" width="42" height="42" viewBox="-1 -1 42 42">' +
      '      <circle stroke-width="1" cx="20" cy="20" r="20"></circle>' +
      '      <path stroke-width="1" stroke-linecap="round" fill="none" d="M22,12 L14,20 L22,28"></path>' +
      '   </svg>' +
      '</a>',
      variableWidth: true,
      centerMode: true,
      slidesToShow: 3,
      centerPadding: '60px',
      speed: 300,
    });
    
    $('#mobile-menu-toggle').click(function () {
      $('.asnier-nav-items').toggleClass('active');
    });
    
    $('.asnier-nav-items').click(function () {
      $('.asnier-nav-items').removeClass('active');
    });
    
    let p = $('.hide-text');
    let pShowButton = $('.show-text');
    let pHeight = p.height();
    p.height(0);
    pShowButton.click(function () {
      p.height(pHeight);
      pShowButton.height(0);
      pShowButton.css('margin', '0')
    });
    
  }
}

export default asnieres;

