import $ from "jquery";


function nav() {
  $('.nav-button.mega-menu').each(function() {
    $(this).mouseenter(function() {
     $(this).addClass('mega-menu-on')
    });
    $(this).mouseleave(function() {
      $(this).removeClass('mega-menu-on')
    })
  })
  
  
  $('.mega-menu-container').each(function () {
    
    let idPoint = $(this).attr('id');
    
    $('#' + idPoint + ' .left-menu-button').each(function () {
      $(this).mouseenter(function() {
        let toggler = $(this).attr('data-toggle');
        $('#' + idPoint + ' .left-menu-button').each(function () {
          $(this).removeClass('active');
        });
        $('#' + idPoint + ' .middle-menu').each(function () {
          $(this).removeClass('active');
        });
        $('#' + idPoint + ' .right-img').each(function () {
          $(this).removeClass('active');
        });
        $(this).addClass('active');
        $('.' + toggler).addClass('active');
      });
    })
  });
  
  let mnav = $('#mobile-nav');
  let mobileNavMain = $('.mobile-nav-main')
  
  let mobileNavHeight = 0;
  mobileNavMain.each(function () {
    mobileNavHeight += $(this).height();
  });
  
  
  $('#mobile-nav-toggle').click(function () {
    
    mnav.toggleClass('active');
    $('.mobile-nav-main').each(function (){
      $(this).removeClass('active');
    })
  });
  
  let mobSubToggle = $('.main-content-header.is-openend');
  
  $('.nav-main-header').each(function () {
    let toogler = $(this).attr('data-toggler');
    let el = $('#' + toogler);
    
    $(this).click(function () {
      mobileNavMain.each(function () {
        $(this).removeClass('active');
      });
      $('.main-content-sublink.is-opened').each(function () {
        $(this).removeClass('active');
      });
      mobSubToggle.each(function () {
        $(this).removeClass('active');
      });
      el.addClass('active')
    });
  });
  
  
  mobSubToggle.each(function () {
    let toogler = $(this).attr('data-toggler');
    let el = $('#' + toogler);
    $(this).click(function () {
      mobSubToggle.each(function () {
        $(this).removeClass('active');
      });
      $('.main-content-sublink.is-opened').each(function () {
        $(this).removeClass('active');
      });
      el.addClass('active');
      $(this).addClass('active');
    })
  });
  
  $('#search-button').click(function () {
    $('#search-area').toggleClass('active');
  })
  
  
  
}

export default nav;