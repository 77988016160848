import $ from 'jquery';
window.jQuery = $;
window.$ = $;
import popper from 'popper.js';
import bootstrap from 'bootstrap';
import 'slick-carousel';
import './panzoom';
import iziModal from '../../node_modules/izimodal/js/iziModal.min.js';
import asnieres from './asnieres';
import home from './home';
import productIndex from "./productIndex";
import productGrid from "./productGrid";
import exhibitions from "./exhibitions";
import cart from "./cart";
import nav from "./nav";


$.fn.iziModal = iziModal;



jQuery(function() {
  jQuery(document).ready(function(){
    
    nav();
    home();
    asnieres();
    productGrid();
    productIndex();
    exhibitions();
    cart();
  });
  

});
