import $ from "jquery";
import productIndex from "./productIndex";

function addFilterHoverEvent(){
  $('.thumbfacet').each(function(){
    var screen = $(this).attr("data-screen");
    $(this).mouseenter(function() {
      var text = $(this).children().attr("alt");
      $('#' + screen).text(text);
    })
    $(this).mouseleave(function() {
      $('#' + screen).text("");
    })
  })
};

function productGrid() {
  
  $(".filters-togglebtn").click(function(){
    $(".filters-body-wrapper").toggleClass("is-open");
    $('#FiltersZone').toggleClass("is-open");
    $(".filters-body").toggleClass("is-open");
    $(".filters-togglebtn").toggleClass("is-open");
    $(".filters-togglebtn").toggleClass("is-closed");
  })
  
  $("#FiltersZone").click(function(){
    $(".filters-body-wrapper").toggleClass("is-open");
    $('#FiltersZone').toggleClass("is-open");
    $(".filters-body").toggleClass("is-open");
    $(".filters-togglebtn").toggleClass("is-open");
    $(".filters-togglebtn").toggleClass("is-closed");
  })
  
  $(".facetgroup").click(function(){
    $( this ).toggleClass("is-open");
    $(".filters-body-wrapper").addClass("is-open");
    $('#FiltersZone').addClass("is-open");
    $(".filters-body").addClass("is-open");
    $(".filters-togglebtn").addClass("is-open");
    
  })
  
  addFilterHoverEvent();
  
}

export default productGrid;