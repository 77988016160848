import $ from "jquery";


function home() {
  if ($('#home')[0]) {
    $('#home-slider').slick({
      nextArrow: '<a class="asnier-modal-button asnier-modal-button_next" href="#">' +
      '   <svg aria-hidden="true" focusable="false" xmlns="http://www.w3.org/2000/svg" version="1.1" width="42" height="42" viewBox="-1 -1 42 42">' +
      '      <circle stroke-width="1" cx="20" cy="20" r="20"></circle>' +
      '      <path stroke-width="1" stroke-linecap="round" fill="none" d="M18,12 L26,20 L18,28"></path>' +
      '   </svg>' +
      '</a>',
      prevArrow: '<a class="asnier-modal-button asnier-modal-button_priv" href="#">' +
      '   <svg aria-hidden="true" focusable="false" xmlns="http://www.w3.org/2000/svg" version="1.1" width="42" height="42" viewBox="-1 -1 42 42">' +
      '      <circle stroke-width="1" cx="20" cy="20" r="20"></circle>' +
      '      <path stroke-width="1" stroke-linecap="round" fill="none" d="M22,12 L14,20 L22,28"></path>' +
      '   </svg>' +
      '</a>',
      slidesToShow: 1,
      speed: 300,
      infinite: true,
      adaptiveHeight: true
    });
  }
}

export default home;