import $ from "jquery";



function productIndex() {
  if ($('#product-index')[0]){
  
    
  
  
  
    $('.productDescriptionMenu').click(function(e){
      e.preventDefault();
      $(this).closest('li').find('.textClientInfo').slideToggle();
    });
  
    
    
    $('.slideshow').slick({
      prevArrow: "<button class='navigationBtn product_arrowLeft'><svg aria-hidden='true' focusable='false' width='18.64px' height='30.84px' viewBox='0 0 8.64 15.84' class='currentColor' role='img'><path style='fill-rule:evenodd;clip-rule:evenodd;fill:#464646;' d='M7.703-0.007v0.462h0.921v-0.462H7.703z M8.142,0.475H7.22v0.461h0.922V0.475z M7.661,0.957H6.739v0.462h0.922V0.957z M7.179,1.438H6.257V1.9h0.922V1.438z M6.697,1.92H5.776v0.462h0.921V1.92z M7.687,15.823h0.922v-0.462H7.687V15.823z M8.127,14.88H7.206v0.461h0.922V14.88z M7.645,14.398H6.724v0.461h0.921V14.398z M7.164,13.917H6.243v0.461h0.921V13.917z M6.684,13.451H5.762v0.462h0.922V13.451z M6.204,2.406H5.283v0.461h0.921V2.406z M5.729,12.481H4.807v0.462h0.922V12.481z M5.247,12H4.326v0.461h0.921V12z M4.765,11.518H3.844v0.462h0.921V11.518z M4.284,11.037H3.362v0.461h0.921V11.037z M3.804,10.571H2.882v0.462h0.921V10.571z M6.208,12.961H5.288v0.462h0.921V12.961z M2.849,9.602H1.927v0.462h0.922V9.602z M2.367,9.12H1.446v0.461h0.921V9.12z M1.885,8.638H0.964V9.1h0.921V8.638z M1.404,8.156H0.482v0.462h0.921V8.156z M0.924,7.691H0.002v0.462h0.921V7.691z M3.329,10.081H2.407v0.462h0.922V10.081z M3.824,4.793H2.902v0.461h0.921V4.793z M3.342,5.275H2.421v0.461h0.921V5.275z M2.86,5.757H1.939v0.461H2.86V5.757z M2.379,6.238H1.458V6.7h0.921V6.238z M1.897,6.72H0.976v0.462h0.921V6.72z M1.404,7.206H0.482v0.462h0.921V7.206z M5.729,2.874H4.807v0.461h0.922V2.874z M5.247,3.355H4.326v0.461h0.921V3.355z M4.765,3.836H3.844v0.462h0.921V3.836z M4.284,4.318H3.362V4.78h0.921V4.318z'></path></svg></button>",
      nextArrow:"<button class='navigationBtn product_arrowRight'><svg aria-hidden='true' focusable='false' width='18.64px' height='30.84px' viewBox='0 0 8.64 15.84' class='currentColor' role='img'><path style='fill-rule:evenodd;clip-rule:evenodd;fill:#464646;' d='M7.715,7.691v0.462h0.922V7.691H7.715z M0.938-0.007H0.016 v0.462h0.922V-0.007z M0.498,0.937h0.921V0.475H0.498V0.937z M0.979,1.418H1.9V0.957H0.979V1.418z M1.46,1.9h0.922V1.438H1.46V1.9z M1.942,2.382h0.921V1.92H1.942V2.382z M0.031,15.823h0.921v-0.462H0.031V15.823z M0.512,15.341h0.922V14.88H0.512V15.341z M0.994,14.859h0.921v-0.461H0.994V14.859z M1.476,14.378h0.922v-0.461H1.476V14.378z M1.956,13.913h0.922v-0.462H1.956V13.913z M2.436,2.868h0.922V2.406H2.436V2.868z M2.911,12.943h0.922v-0.462H2.911V12.943z M3.393,12.461h0.921V12H3.393V12.461z M3.874,11.979h0.921v-0.462H3.874V11.979z M4.356,11.498h0.921v-0.461H4.356V11.498z M4.835,11.033h0.922v-0.462H4.835V11.033z M2.431,13.423h0.922v-0.462H2.431V13.423z M5.791,10.063h0.922V9.602H5.791V10.063z M6.272,9.581h0.922V9.12H6.272V9.581z M6.754,9.1h0.921V8.638H6.754V9.1z M7.236,8.618h0.922V8.156H7.236V8.618z M5.311,10.543h0.921v-0.462H5.311V10.543z M4.816,5.255h0.922V4.793H4.816V5.255z M5.297,5.736h0.922V5.275H5.297V5.736z M5.779,6.218h0.922V5.757H5.779V6.218z M6.26,6.7h0.922V6.238H6.26V6.7z M6.743,7.182h0.921V6.72H6.743V7.182z M7.236,7.668h0.922V7.206H7.236V7.668z M2.911,3.335h0.922V2.874H2.911V3.335z M3.393,3.816h0.921V3.355H3.393V3.816z M3.874,4.298h0.921V3.836H3.874V4.298z M4.356,4.78h0.921V4.318H4.356V4.78z'></path></svg></button>",
    });
    
    if($("#modal")[0]) {
      $("#modal").iziModal(
        {
          openFullscreen: true,
          transitionIn : 'fadeInUp',
        }
      );
    }
    
    $('.miniSlideshow').slick({
      slidesToShow: 4,
      slidesToScroll: 1,
      vertical: true,
      verticalSwiping: true,
      infinite: false,
      nextArrow: "<a href='' class='product_miniArrowDown'></a>",
      prevArrow: "<a href='' class='product_miniArrowUp'></a>",
      
      
      responsive: [
        {
          breakpoint: 1420,
          settings: {
            vertical: true,
            verticalSwiping: true
          }
        },
        {
          breakpoint: 768,
          settings: {
            vertical: false,
            verticalSwiping: false,
            arrows: false,
          }
        },
      ]
    });
    
    $('.miniSlideshow_item').each(function() {
      var slide = $(this).attr('data-slide');
      $(this).click(function () {
        $('.slideshow').slick('slickGoTo', slide);
        $('.miniSlideshow_item').each(function() {
          $(this).removeClass('active');
        });
        $(this).addClass('active');
      });
    });
    
    $('.slideshow').on('beforeChange', function(event, slick, currentSlide, nextSlide){
      $('.miniSlideshow_item').each(function() {
        if ($(this).attr('data-slide') == nextSlide){
          $(this).addClass('active');
        }
        else{
          $(this).removeClass('active');
        }
        
      });
      
    });
    
    $('.miniSlideshow').on('breakpoint', function(event, slick, breakpoint) {
      $('.miniSlideshow_item').each(function() {
        var slide = $(this).attr('data-slide');
        $(this).click(function () {
          $('.slideshow').slick('slickGoTo', slide);
          $('.miniSlideshow_item').each(function() {
            $(this).removeClass('active');
          });
          $(this).addClass('active');
        });
      });
    });
  
    if($("#modal-product-care")[0]) {
    
      $("#modal-product-care").iziModal({
        transitionIn: 'fadeInUp',
        width: "60%",
      });
    }
  
    $('#productCare').click(function () {
      $("#modal-product-care").iziModal('open', {zindex: 99999});
    });
  
    $('#closeButtonCare').click(function(e){
      $('#modal-product-care').iziModal('close');
    });
  
    $(".zoom-img").panzoom({
      minScale: 1,
      maxScale: 3,
      increment: 1,
      duration: 1000,
    });
  
    $(document).on('click', '.trigger', function (event) {
      event.preventDefault();
      // $('#modal').iziModal('setZindex', 99999);
      // $('#modal').iziModal('open', { zindex: 99999 });
      var img = $(this).attr("data-img");
      $('.zoom-img').attr('src', img);
      $(".zoom-img").panzoom("resetZoom", true);
      $(".zoom-img").panzoom("resetPan");
      $(".zoom-wraper").attr('data-scale', 1);
      $(".scroller-zoom-in").addClass('active')
      $(".scroller-zoom-out").removeClass('active');
      $("#resetZoomButton").removeClass('active');
      $('#modal').iziModal('open', { zindex: 99999 });
    
    });
  
    $('#closeButtonZoomer').click(function(e){
      $('#modal').iziModal('close');
    });
  
    $(".scroller-zoom-in").on("click", function( e ) {
      e.preventDefault();
      var scale = $(".zoom-wraper").attr('data-scale');
      if (scale == 1) {
        $(".zoom-img").panzoom("zoom");
        $(".zoom-wraper").attr('data-scale', 2);
        $(".scroller-zoom-out").addClass('active');
        $("#resetZoomButton").addClass('active');
      
      }
      else if(scale == 2) {
        $(".zoom-img").panzoom("zoom");
        $(".zoom-wraper").attr('data-scale', 3);
        $(".scroller-zoom-in").removeClass('active')
      }
      else {
      
      }
    });
  
    $(".scroller-zoom-out").on("click", function( e ) {
      e.preventDefault();
      var scale = $(".zoom-wraper").attr('data-scale');
      if (scale == 3) {
        $(".zoom-img").panzoom("zoom", true);
        $(".zoom-wraper").attr('data-scale', 2);
        $(".scroller-zoom-in").addClass('active')
      }
      else if(scale == 2) {
        $(".zoom-img").panzoom("zoom", true);
        $(".zoom-wraper").attr('data-scale', 1);
        $(".scroller-zoom-out").removeClass('active');
      }
      else {
      
      }
    });
  
    $("#resetZoomButton").on("click", function( e ) {
      e.preventDefault();
      $(".zoom-img").panzoom("resetZoom", true);
      $(".zoom-img").panzoom("resetPan");
      $(".zoom-wraper").attr('data-scale', 1);
      $(".scroller-zoom-in").addClass('active')
      $(".scroller-zoom-out").removeClass('active');
      $("#resetZoomButton").removeClass('active');
    });
  
    $(".zoom-wraper").dblclick(function() {
      var scale = $(this).attr('data-scale');
      if (scale == 1) {
        $(".zoom-img").panzoom("zoom");
        $(this).attr('data-scale', 2);
        $(".scroller-zoom-out").addClass('active');
        $("#resetZoomButton").addClass('active');
      
      }
      else if(scale == 2) {
        $(".zoom-img").panzoom("zoom");
        $(this).attr('data-scale', 3);
        $(".scroller-zoom-in").removeClass('active')
      }
      else {
        $(".zoom-img").panzoom("resetZoom", true);
        $(this).attr('data-scale', 1);
        $(".scroller-zoom-in").addClass('active')
        $(".scroller-zoom-out").removeClass('active');
      }
    
    
    });
    
    
  }
}

export default productIndex;