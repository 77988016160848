import $ from "jquery";


function cart() {
  if ($('#cart')[0]){
  
    $("#modal-delivery").iziModal({
      transitionIn: 'fadeInUp',
      width: "60%",
    });
    $("#modal-payment").iziModal({
      transitionIn: 'fadeInUp',
      width: "60%",
    });
    $("#modal-return").iziModal({
      transitionIn: 'fadeInUp',
      width: "60%",
    });
    $("#modal-client").iziModal({
      transitionIn: 'fadeInUp',
      width: "60%",
    });
    
    $('#open-delivery').click(function () {
      $("#modal-delivery").iziModal('open', {zindex: 99999});
    });
    $('#open-payment').click(function () {
      $("#modal-payment").iziModal('open', {zindex: 99999});
    });
    $('#open-return').click(function () {
      $("#modal-return").iziModal('open', {zindex: 99999});
    });
    $('#open-client').click(function () {
      $("#modal-client").iziModal('open', {zindex: 99999});
    });
    
    $('#close-delivery').click(function(e){
      $('#modal-delivery').iziModal('close');
    });
    $('#close-payment').click(function(e){
      $('#modal-payment').iziModal('close');
    });
    $('#close-return').click(function(e){
      $('#modal-return').iziModal('close');
    });
    $('#close-client').click(function(e){
      $('#modal-client').iziModal('close');
    });
    
  }
  
  let persToggler = $('#personal-text-toggle');
  
  if (persToggler[0]) {
    persToggler.click(function () {
      $('#personal-text').toggleClass('active');
      persToggler.toggleClass('active');
    })
  }
  
}

export default cart;