import $ from "jquery";


function exhibitions() {
  if ($('#exhibitions')[0]) {
    
    $('.asnier-atelier-slider').slick({
      nextArrow: '<a class="asnier-modal-button asnier-modal-button_next" href="#">' +
      '   <svg aria-hidden="true" focusable="false" xmlns="http://www.w3.org/2000/svg" version="1.1" width="42" height="42" viewBox="-1 -1 42 42">' +
      '      <circle stroke-width="1" cx="20" cy="20" r="20"></circle>' +
      '      <path stroke-width="1" stroke-linecap="round" fill="none" d="M18,12 L26,20 L18,28"></path>' +
      '   </svg>' +
      '</a>',
      prevArrow: '<a class="asnier-modal-button asnier-modal-button_priv" href="#">' +
      '   <svg aria-hidden="true" focusable="false" xmlns="http://www.w3.org/2000/svg" version="1.1" width="42" height="42" viewBox="-1 -1 42 42">' +
      '      <circle stroke-width="1" cx="20" cy="20" r="20"></circle>' +
      '      <path stroke-width="1" stroke-linecap="round" fill="none" d="M22,12 L14,20 L22,28"></path>' +
      '   </svg>' +
      '</a>',
      variableWidth: true,
      centerMode: true,
      slidesToShow: 3,
      centerPadding: '60px',
      speed: 300,
    });
    
    let p = $('.hide-text');
    let pShowButton = $('.show-text');
    let pHeight = p.height();
    p.height(0);
    pShowButton.click(function () {
      p.height(pHeight);
      pShowButton.height(0);
      pShowButton.css('margin', '0')
    });
  }
}

export default exhibitions;